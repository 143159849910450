import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';


function Ueberuns() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});



  const properties = {
    duration: 2000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    cssClass: "height: 50%",
    transitionDuration: 500,
    prevArrow: <button><a class="prev">&#10094;</a></button>,
    nextArrow: <button><a class="next">&#10095;</a></button>,
  };

  const srcImages = ["./images/lift4m.png","./images/boomtonedj_neu.png"];



  return (
        
        


    <>
     <Navbar active={"Ueberuns"}></Navbar>


    <div class="placeholder"></div>
    <p class="slidetitel">Über uns</p>


      

    
    
<section>



<article className='uebermargin'>
  <p class="p">
    <h1>
    Wir sind ein kleines, aber schlagkräftiges Team. Flexibel und umsetzungsstark engagieren wir uns für deinen Event.
    </h1>
  </p>
</article>

<article>
      <p class="teamtitel">
          Das Team
      </p>
      <div class="teamdeco"></div>
</article>

</section>





<section class="">

<article class="">
  <img src="./images/team.png" alt="Team" class="picUeber"/>
  <h4 class="h4Ueber">
    <div class="titelUeber">Unsere Geschäftsführer</div> 
  </h4>
</article>

<article class="">
  <img src="./images/micha_portrait.png" alt="MichaFormal" class="picUeber"/>
  <h4 class="h4Ueber">
    <div class="titelUeber" margin-top="5%"> <a class="kontlink" href="./micha">Micha Hösle</a></div>
    <div>Micha Hösle ist in der Lehre zum Veranstaltundsfachmann EFZ.</div>
    <div>Er hat seine Leidenschaft zur Eventtechnik bereits als Kind</div>
    <div>gefunden. Er ist eines unserer Gründungsmitglieder.</div>
    <div> <a class="kontlink" href="./micha">Kontakt</a></div>
  </h4>
</article>

<article class="">
  <img src="./images/noah_portrait.png" alt="NoahFormal" class="picUeber"/>
  <h4 class="h4Ueber">
    <div class="titelUeber" margin-top="5%"> <a class="kontlink" href="./noah">Noah Rossel</a></div>
    <div>Noah Rossel arbeitet in einem anderen Gebiet, er arbeitet als</div>
    <div>Automatiker. Er wuchs durch einige Events als freiwilliger Helfer</div>
    <div>in die Thematik der Eventtechnik hinein. Er ist eines unserer</div>
    <div>Gründungsmitglieder.</div>
    <div> <a class="kontlink" href="./noah">Kontakt</a></div>
  </h4>
</article>


</section>



    </>
    
  )
}

export default Ueberuns;