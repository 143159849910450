import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import Iframe from 'react-iframe'



function Kontakt() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});


  const properties = {
    duration: 2000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    transitionDuration: 500,
    cssClass: "height: 50%",
    prevArrow: <button><a class="prev">&#10094;</a></button>,
    nextArrow: <button><a class="next">&#10095;</a></button>,
  };

  const srcImages = ["./images/lift4m.png","./images/boomtonedj_neu.png", "./images/tourhazer.png"];



  return (
        
        


    <>
     <Navbar active={""}></Navbar>


    <div class="placeholder"></div>
    <p class="slidetitel">Kontakt Noah Rossel</p>


      


    
<section>

<article class="placeholder4 kntmargin">
      <p class="kontakttitel">
        Noah Rossel
      </p>
      <div class="kontaktdeco"></div>
      <div class="kontakttext">
        <img src="./images/noah_portrait.png" alt="Team" class="picqr" />
        <p>Noah Rossel</p>
        <p>Eventindustry GmbH</p>
        <p>Im Luchli 1</p>
        <p>CH 3110 Münsingen</p>
        <p>E-Mail: <a class="kontlink" href="mailto:noah.rossel@eventindusty.ch">noah.rossel@eventindustry.ch</a></p>
        <p>Telefon: <a class="kontlink" href="tel::+41799673577">+41 79 967 35 77</a></p>
        </div>
    <div class="kontakttext2">
        <p>Zu Kontakten <a class="kontlink" href="./noah_eventindustry.vcf" download>hinzufügen </a></p>
        <p>Oder Scanne folgenden QR-Code:</p>
        <img src="./images/qr_code_noah_eventindustry.png" alt="Team" class="picqr" />
    </div>
</article>



</section>



    </>
    
  )
}

export default Kontakt;