import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';


function Home() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});



  const properties = {
    duration: 2000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    cssClass: "height: 50%",
    transitionDuration: 500,
    prevArrow: <button><a class="prev">&#10094;</a></button>,
    nextArrow: <button><a class="next">&#10095;</a></button>,
  };

  const srcImages = ["./images/lift4m.png","./images/boomtonedj_neu.png", "./images/tourhazer.png"];



  return (
        
        


    <>
    <Navbar active={"Home"}></Navbar>

  
      <div class="placeholder"></div>
  


    <section>
      <article>
        <img src="./images/logo.png" alt="Logo" class="logo_home"/>

      </article>
    </section>





      

    <div class="slideshow-container">

      <div style={{ width: "100%", height: "auto" }}>
        <Slide {...properties}>
          {srcImages.map((each, index) => (
            <img
              style={{ objectFit: "contain", width: "100%" }}
              src={each}
              key={index}
              alt={"Bild: "+ index}
            />
          ))}
        </Slide>
      </div>

    </div>

    
    <section>

      <article>
        <h1>
          Herzlich Willkommen bei der Eventindustry GmbH
        </h1>
      </article>

      <article>
      <h2>
        <p class="p">
          Wir bieten dir die technischen Lösungen für deinen Event. 
          Wir stellen dich ins richtige Licht. 
          Mit uns hören dich bestimmt alle. 
          Deine Botschaft kann dank unserer Technik auf der ganzen Welt angeschaut werden. 
          Auch vor Ort ist das Setup deiner Botschaft würdig.
        </p>
        <p class="p margin-top">
          Die Eventindustry GmbH freut sich auf die nächste Veranstaltung mit dir! Melde dich bei uns, damit wir für dich das passende Paket zusammenstellen dürfen.
        </p>
      </h2>
      </article>

    </section>

    <section class="grid-container">
      <article class="grid-item">

        <Link to="/vermietung"><h2 class="h2vermietung">Vermietung</h2></Link>
    
        <Link to="/vermietung"><img src="./images/vermietung.png" alt="Vermietung" class="imgvermietung"/></Link>
      </article>
   

      <article class="grid-item">
        
        <Link to="/ueberuns"><h2 class="h2ueber">Über uns</h2></Link>
      
        <Link to="/ueberuns"><img src="./images/team.png" alt="Team" class="imgteam"/></Link> 
      </article>
    </section>

    </>
    
  )
}

export default Home;